import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from '@mui/material'
import { memo } from 'react'
import { globalGrey } from '~/modules/AppLayout/Colors'
import { fontWeight600 } from '~/css/font'
import { flex } from '~/modules/AppLayout/FlexGridCss'

type BasicProps = {
  statusColor?: boolean
  hoverColor?: boolean
  mode: 'dark' | 'light'
}

const switchHeaderCss = css`
  ${fontWeight600};
  font-size: 18px;
  margin: 0px 4px 0px 8px;

  &.dark {
    color: #fafafa;
  }
  &.light {
    color: #454545;
  }
`

const TooltipDescribe = styled.div`
  width: 100%;
  font-size: 14px;
`

const SwitchButton = styled.div`
  ${flex.h.allCenter}
`

const theme = {
  dark: {
    uncheckedLeft: '#5A7685',
    uncheckedHoverLeft: '#495F6B',
    uncheckedRight: '#2F3E45',
    uncheckedHoverRight: '#374952',
    checkedLeft: '#2F3E45',
    checkedHoverLeft: '#374952',
    checkedRight: '#5A7685',
    checkedHoverRight: '#495F6B',
    checkedText: '#fafafa',
    uncheckedText: '#fafafa',
  },
  light: {
    uncheckedLeft: globalGrey.g400,
    uncheckedHoverLeft: globalGrey.g400,
    uncheckedRight: globalGrey.g100,
    uncheckedHoverRight: globalGrey.g100,
    checkedLeft: globalGrey.g100,
    checkedHoverLeft: globalGrey.g100,
    checkedRight: globalGrey.g400,
    checkedHoverRight: globalGrey.g400,
    checkedText: '#353535',
    uncheckedText: '#aaaaaa',
  },
}

const InsideButtonLeft = styled.div<BasicProps>`
  ${flex.h.allCenter};
  width: 50px;
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  color: ${props =>
    props.statusColor ? theme[props.mode].uncheckedText : theme[props.mode].checkedText};
  background-color: ${props =>
    props.statusColor ? theme[props.mode].checkedLeft : theme[props.mode].uncheckedLeft};
  border: 1px solid #666666;
  border-right: none;
  &:hover {
    background-color: ${props =>
      props.hoverColor ? theme[props.mode].checkedHoverLeft : theme[props.mode].uncheckedHoverLeft};
  }
  transition: 0.3s;
`

const InsideButtonRight = styled.div<BasicProps>`
  ${flex.h.allCenter};
  width: 50px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  color: ${props =>
    props.statusColor ? theme[props.mode].checkedText : theme[props.mode].uncheckedText};
  background-color: ${props =>
    props.statusColor ? theme[props.mode].checkedRight : theme[props.mode].uncheckedRight};
  border: 1px solid #666666;
  border-left: none;
  &:hover {
    background-color: ${props =>
      props.hoverColor
        ? theme[props.mode].checkedHoverRight
        : theme[props.mode].uncheckedHoverRight};
  }
  transition: 0.3s;
`

export const WordBlockSwitch = memo(function WordBlockSwitch(props: {
  tooltip?: string
  title?: string
  labelOff: string
  labelOn: string
  checked: boolean
  delegate: (check: boolean) => void
  rootCSS?: SerializedStyles
  theme: 'light' | 'dark'
}) {
  return (
    <Tooltip
      title={<TooltipDescribe>{props.tooltip}</TooltipDescribe>}
      placement='bottom'
    >
      <div
        css={css`
          ${flex.h.crossCenter};
          ${props.rootCSS};
        `}
      >
        <SwitchButton>
          {props.title && (
            <div
              css={switchHeaderCss}
              className={props.theme}
            >
              {props.title}
            </div>
          )}
          <InsideButtonLeft
            mode={props.theme}
            statusColor={props.checked}
            hoverColor={props.checked}
            onClick={() => props.delegate(false)}
          >
            {props.labelOff}
          </InsideButtonLeft>
          <InsideButtonRight
            mode={props.theme}
            statusColor={props.checked}
            hoverColor={props.checked}
            onClick={() => props.delegate(true)}
          >
            {props.labelOn}
          </InsideButtonRight>
        </SwitchButton>
      </div>
    </Tooltip>
  )
})
